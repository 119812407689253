import React from 'react';
import './ShortServicesMini.scss';
import { ReactComponent as Description } from '../../../assets/shortServices/description.svg';

const ShortServicesMini = ({ img, title, description }) => {
  return (
    <div className="short-services-mini-container">
      <video
        className="short-services-mini-video"
        autoPlay
        loop
        muted
        style={{ borderRadius: '30px' }}
        playsInline
        webkit-playsinline=""
        x-webkit-airplay="allow"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        x5-video-orientation="portraint"
      >
        <source src={img} type="video/mp4" />
      </video>
      <div className="short-services-mini-text">
        <h3 className="short-services-mini-title">{title}</h3>
        <p className="short-services-mini-description">
          {description} <Description />
        </p>
      </div>
    </div>
  );
};

export default ShortServicesMini;
