import React from 'react';
import './SelectBlog.scss';
import { useParams } from 'react-router-dom';
import { blog, getAdjacentArticles } from '../../../assets/blog/index';
import NotFound from '../NotFound/NotFound';
import { useTranslation } from 'react-i18next';
import MiniBlog from './MiniBlog';
import ArrowButton from '../../utilsComponents/ArrowButton';
import { Helmet } from 'react-helmet';

const SelectBlog = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const selectBlog = blog.find((x) => x.id == id);
  const similarArticles = getAdjacentArticles(id);

  if (selectBlog === undefined) return <NotFound />;

  const metaTitle = t('Blog.title' + selectBlog.id);
  const metaDescription = t('Blog.description' + selectBlog.id);
  const metaKeywords = t('Blog.keywords');
  const metaRobots = 'index, follow';

  const canonicalUrl = `${window.location.origin}${
    i18n.language == 'ua' ? '' : `/${i18n.language}`
  }/blog/${id}`;

  const alternateUrls = {
    uk: `${window.location.origin}/selectblog/${id}`,
    ru: `${window.location.origin}/ru/selectblog/${id}`,
    en: `${window.location.origin}/en/selectblog/${id}`,
  };

  return (
    <>
      <Helmet>
        {metaTitle && <title>{metaTitle}</title>}
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        <meta name="keywords" content={metaKeywords} />
        <meta name="robots" content={metaRobots} />
        <link rel="canonical" href={canonicalUrl} />
        {Object.keys(alternateUrls).map((lang) => (
          <link
            key={lang}
            rel="alternate"
            hrefLang={lang}
            href={alternateUrls[lang]}
          />
        ))}
      </Helmet>
      <div className="select-blog-container">
        <div className="select-blog-img-video-container">
          {selectBlog.video === '' ? (
            <img src={selectBlog.img} alt={metaTitle} />
          ) : (
            <video src={selectBlog.video} controls />
          )}
        </div>
        <h1>{metaTitle}</h1>
        <div
          className="select-blog-desctription"
          dangerouslySetInnerHTML={{
            __html: metaDescription,
          }}
        />
        <div className="select-blog-similar-article">
          <div
            className="select-blog-similar-article-title"
            style={{
              textWrap: 'nowrap',
              flexWrap: 'nowrap',
              whiteSpace: 'nowrap',
            }}
          >
            {t('Blog.similar')}
            <br />
            <div className="select-blog-line">&nbsp;</div>
          </div>
          <div className="list-similar">
            {similarArticles.map((x) => (
              <MiniBlog key={x.id} article={x} />
            ))}
          </div>
        </div>
        <br />
        <ArrowButton />
      </div>
    </>
  );
};

export default SelectBlog;
