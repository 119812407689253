import React from 'react';
import './MiniBlog.scss';
import { t } from 'i18next';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Video } from '../../../assets/svg/Video.svg';
import i18n from '../../../i18n';

const MiniBlog = ({ article }) => {
  return (
    <NavLink
      to={`${i18n.language == 'ua' ? '' : '/' + i18n.language}/selectblog/${
        article.id
      }`}
    >
      <div className="mini-blog">
        <div className="img-container">
          {article.video == '' ? (
            <></>
          ) : (
            <div className="svg-container">
              <Video />
            </div>
          )}
          <img src={article.img} alt={article.name} />
        </div>
        <div className="text-container">
          <h2>{t('Blog.title' + article.id)}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: t('Blog.description' + article.id),
            }}
            className="desctiption"
          />
          <div className="title-and-info">
            <div className="views">
              {article.views} {t('Blog.views')}
            </div>
            <div className="date">{article.date}</div>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default MiniBlog;
