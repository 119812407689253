import React from 'react';
import './SocialNetworks.scss';
import { ReactComponent as TikTok } from '../../assets/svg/socialNetworks/TikTok.svg';
import { ReactComponent as Instagram } from '../../assets/svg/socialNetworks/Instagram.svg';
import { ReactComponent as YouTube } from '../../assets/svg/socialNetworks/YouTube.svg';
import { ReactComponent as Telegram } from '../../assets/svg/socialNetworks/Telegram.svg';
import { NavLink } from 'react-router-dom';

const SocialNetworks = () => {
  return (
    <div className="list-icon-main">
      <NavLink
	target="_blank"
        to={
          'https://api.whatsapp.com/send/?phone=380982226951&text&type=phone_number&app_absent=0'
        }
      >
        <div className="social-network-icon">
          <TikTok />
        </div>
      </NavLink>
      <NavLink
	target="_blank"
        to={
          'https://www.instagram.com/afina______gadanie?igsh=MXUxcWdqMzNlNXFkaw=='
        }
      >
        <div className="social-network-icon">
          <Instagram />
        </div>
      </NavLink>

      <NavLink target="_blank" to={'viber://contact?number=380982226951'}>
        <div className="social-network-icon">
          <YouTube />
        </div>
      </NavLink>
      <NavLink target="_blank" to={'https://t.me/Valdemarmag'}>
        <div className="social-network-icon">
          <Telegram />
        </div>
      </NavLink>
    </div>
  );
};

export default SocialNetworks;
