import React from 'react';
import imgStagesDevelopment from '../../../assets/StagesDevelopment/image.mp4';
import './StagesDevelopment.scss';
import { t } from 'i18next';
import { ReactComponent as Line } from '../../../assets/StagesDevelopment/line.svg';

const StagesDevelopment = () => {
  return (
    <div className="stages-development-container">
      <div className="stages-development-img">
        <video
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline=""
          x-webkit-airplay="allow"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-orientation="portraint"
        >
          <source src={imgStagesDevelopment} type="video/mp4" />
        </video>
      </div>
      <div className="stages-development-text">
        <h2 className="stages-development-title">
          <div className="stages-development-line">
            <Line />
          </div>
          {t('Home.stages-development-title')}
        </h2>
        <ul className="stages-development-info">
          <li>{t('Home.stages-development-description1')}</li>
          <li>{t('Home.stages-development-description2')}</li>
          <li>{t('Home.stages-development-description3')}</li>
          <li>{t('Home.stages-development-description4')}</li>
        </ul>
      </div>
    </div>
  );
};

export default StagesDevelopment;
