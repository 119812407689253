import React, { useRef, useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import './Blog.scss';
import {
  blogVideo,
  blogBracelet,
  blogCoulomb,
  blogAmulet,
} from '../../../assets/blog/index';
import MiniBlog from './MiniBlog';
import { ReactComponent as ArrowLeft } from '../../../assets/svg/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../assets/svg/ArrowRight.svg';
import NotFound from '../NotFound/NotFound';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import ArrowButton from '../../utilsComponents/ArrowButton';
import SortBy from './SortBy';

const Blog = () => {
  const { t } = useTranslation();
  const { sort } = useParams();
  const itemsPerPage = 10;
  const containerRef = useRef(null);

  // Визначення блогу на основі параметра сортування
  const selectListBlog =
    sort === 'video'
      ? blogVideo
      : sort === 'bracelets'
      ? blogBracelet
      : sort === 'amulets'
      ? blogAmulet
      : blogCoulomb;

  // Обчислення загальної кількості сторінок
  const totalPages = Math.ceil(selectListBlog.length / itemsPerPage);

  // Отримання поточної сторінки
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const pageFromPath = parseInt(pathParts[pathParts.length - 1], 10);

  const [currentPage, setCurrentPage] = useState(
    isNaN(pageFromPath) || pageFromPath < 1 || pageFromPath > totalPages
      ? 1
      : pageFromPath
  );

  // Якщо сторінка не існує
  if (currentPage < 1 || currentPage > totalPages) {
    return <NotFound />;
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = selectListBlog.slice(startIndex, endIndex);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    if (containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth', // Додає плавний перехід
        block: 'start', // Вирівнювання до верхньої частини контейнера
      });
    }
  };

  // Виправлення перевірки на допустимі значення параметра `sort`
  if (
    sort !== 'video' &&
    sort !== 'bracelets' &&
    sort !== 'amulets' &&
    sort !== 'pendants'
  ) {
    return <NotFound />;
  }

  const renderPagination = () => {
    const pageLinks = [];

    // Previous page
    pageLinks.push(
      <li key="prev" className="previous">
        <NavLink
          style={{ backgroundColor: 'unset' }}
          to={`/${
            i18n.language === 'ua' ? '' : i18n.language + '/'
          }blog/${sort}/${currentPage - 1}`}
          onClick={(e) => {
            if (currentPage > 1) handlePageClick(currentPage - 1);
          }}
          aria-label="Previous page"
          className={currentPage === 1 ? 'disabled' : ''}
        >
          <ArrowLeft />
        </NavLink>
      </li>
    );

    // Page numbers
    for (let i = 1; i <= totalPages; i++) {
      pageLinks.push(
        <li key={i} className={currentPage === i ? 'active' : ''}>
          <NavLink
            to={`/${
              i18n.language === 'ua' ? '' : i18n.language + '/'
            }blog/${sort}/${i}`}
            onClick={() => handlePageClick(i)}
            aria-label={`Page ${i}`}
            aria-current={currentPage === i ? 'page' : undefined}
          >
            {i}
          </NavLink>
        </li>
      );
    }

    // Next page
    pageLinks.push(
      <li key="next" className="next">
        <NavLink
          style={{ backgroundColor: 'unset' }}
          to={`/${
            i18n.language === 'ua' ? '' : i18n.language + '/'
          }blog/${sort}/${currentPage + 1}`}
          onClick={(e) => {
            if (currentPage < totalPages) handlePageClick(currentPage + 1);
          }}
          aria-label="Next page"
          className={currentPage === totalPages ? 'disabled' : ''}
        >
          <ArrowRight />
        </NavLink>
      </li>
    );

    return pageLinks;
  };

  return (
    <div className="blog-main" ref={containerRef}>
      <SortBy active={sort} />
      <div className="list-mini-blog">
        {currentItems.map((article) => (
          <MiniBlog key={article.id} article={article} />
        ))}
      </div>

      <ul className="pagination" role="navigation" aria-label="Pagination">
        {renderPagination()}
      </ul>
      <ArrowButton />
    </div>
  );
};

export default Blog;
