import React from 'react';
import './SortBy.scss';
import { t } from 'i18next';
import i18n from '../../../i18n';
import { NavLink } from 'react-router-dom';

const SortBy = ({ active }) => {
  return (
    <div className="sort-by-main">
      <div className="sort-by-line" />
      <div className="sort-text">
        <div className="sort-title">{t('Blog.sortBy')}</div>
        <div className="sort-list">
          <NavLink
            to={`${
              i18n.language == 'ua' ? '' : '/' + i18n.language
            }/blog/video/1`}
            className={active == 'video' ? 'sort-active' : ''}
          >
            {t('Blog.video')}
          </NavLink>
          <NavLink
            to={`${
              i18n.language == 'ua' ? '' : '/' + i18n.language
            }/blog/bracelets/1`}
            className={active == 'bracelets' ? 'sort-active' : ''}
          >
            {t('Blog.bracelets')}
          </NavLink>
          <NavLink
            to={`${
              i18n.language == 'ua' ? '' : '/' + i18n.language
            }/blog/amulets/1`}
            className={active == 'amulets' ? 'sort-active' : ''}
          >
            {t('Blog.amulets')}
          </NavLink>
          <NavLink
            to={`${
              i18n.language == 'ua' ? '' : '/' + i18n.language
            }/blog/pendants/1`}
            className={active == 'pendants' ? 'sort-active' : ''}
          >
            {t('Blog.pendants')}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default SortBy;
